var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vg_wrapper"},[_c('el-card',[_c('div',{staticClass:"vd_button_group vg_mtb_16"},[_c('el-date-picker',{staticClass:"vg_ml_16 topDateRangeSearch",attrs:{"picker-options":_vm.pickerOptions,"align":"right","end-placeholder":"结束年月","range-separator":"至","size":"small","start-placeholder":"开始年月","type":"monthrange","unlink-panels":"","value-format":"timestamp"},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}}),_c('el-button',{staticClass:"vg_ml_16",attrs:{"icon":"el-icon-search","size":"small","type":"primary"},on:{"click":function($event){return _vm.getTableData()}}},[_vm._v("查询")]),_c('el-button',{staticClass:"vd_export",attrs:{"icon":"el-icon-refresh-right","size":"small","type":"info"},on:{"click":function($event){return _vm.buttonRefresh()}}},[_vm._v("刷新")])],1),_c('search-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadFlag),expression:"loadFlag"}],ref:"searchTable",attrs:{"column-move":false,"columns":_vm.tableProperties,"data":_vm.tableData,"need-fixed-height":true,"need-pagination":false,"needExpand":true,"table-row-key":'indexId'},on:{"expand-change":_vm.getRpinInForm,"getTableData":_vm.getTableData,"selection-change":_vm.handleSelectionChange},scopedSlots:_vm._u([{key:"tran_no",fn:function(scope){return [_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.saveData(scope.row)}}},[_vm._v("保存数据")])]}},{key:"prod_hsnames",fn:function(){return [_c('el-button',{attrs:{"size":"mini","type":"danger"}},[_vm._v("操作数据")])]},proxy:true},{key:"expand",fn:function(scope){return [_c('div',{staticStyle:{"padding-left":"98px"}},[_c('DynamicUTable',{ref:("expandTable" + (scope.$index)),attrs:{"autoHeight":true,"column-move":false,"columns":_vm.expandTableProperties,"computedData":{
              conv_rmb: _vm.conv_rmb,
              paid_in_usd: _vm.paid_in_usd,
              paid_in_rmb: _vm.paid_in_rmb,
              gross_profit_one: _vm.gross_profit_one,
              gross_profit_margin: _vm.gross_profit_margin,
              gross_profit_two: _vm.gross_profit_two,
              exchange_cost: _vm.exchange_cost,
              purchase_service_fee: _vm.purchase_service_fee
            },"isExpand":true,"need-check-box":false,"need-expand":false,"need-index":false,"need-pagination":false,"row-click":function (row, column, event) { return (_vm.currentRow = row); },"showHeader":false,"table-data":scope.row.rpin_months_list}})],1)]}}])}),_c('h2',[_vm._v("托收成本")]),_c('DynamicUTable',{ref:"otherTable",attrs:{"columns":_vm.otherTableProperties,"need-check-box":false,"needPagination":false,"needSearch":false,"tableData":_vm.otherTableData}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }