import { keep2Decimal, keep6Decimal } from '@assets/js/regExUtil';

export const tableProperties = [
  // { prop: 'selection', type: 'selection', labelWidth: '48px' },
  { prop: 'index', type: 'index', labelWidth: '50px', label: '序号' },
  { label: '年月', prop: 'end_month_name', itemType: 'input', input: false, sortable: false, labelWidth: 80, overflowTooltip: true },
  { label: '客户名称', prop: 'cust_abbr', itemType: 'input', input: true, sortable: false, labelWidth: 120, overflowTooltip: true },
  { label: '所属部门', prop: 'dept_name', itemType: 'input', input: true, sortable: false, labelWidth: 120, overflowTooltip: true },
  { label: '出运发票号', prop: 'tran_no', itemType: 'input', input: false, sortable: false, labelWidth: 120 },
  { label: '报关品名', prop: 'prod_hsnames', itemType: 'input', input: false, sortable: false, labelWidth: 120, overflowTooltip: true },
  { label: '实收外汇(＄)', prop: 'rece_total', itemType: 'input', input: false, sortable: false, labelWidth: 120 },
  { label: '应收人民币', prop: 'conv_rmb', itemType: 'input', input: false, sortable: false, labelWidth: 120 },
  { label: '佣金金额(＄)', prop: 'deduct_comm', itemType: 'input', input: false, sortable: false, labelWidth: 120 },
  { label: '扣除佣金后外汇(＄)', prop: 'paid_in_usd', itemType: 'input', input: false, sortable: false, labelWidth: 150 },
  { label: '汇率', prop: 'purchase_rate', itemType: 'input', input: false, sortable: false, labelWidth: 130 },
  { label: '实收人民币', prop: 'paid_in_rmb', itemType: 'input', input: false, sortable: false, labelWidth: 120 },
  { label: '工厂货款', prop: 'factory_loan', itemType: 'input', input: false, sortable: false, labelWidth: 120 },
  { label: '退税额', prop: 'tax_refund', itemType: 'input', input: false, sortable: false, labelWidth: 120 },
  { label: '毛利1', prop: 'gross_profit_one', itemType: 'input', input: false, sortable: false, labelWidth: 120 },
  { label: '包干费(￥)', prop: 'rmb_lumpsum_fee', itemType: 'input', input: false, sortable: false, labelWidth: 120 },
  { label: '代理费(￥)', prop: 'agency_fee', itemType: 'input', input: false, sortable: false, labelWidth: 120 },
  { label: '毛利2', prop: 'gross_profit_two', itemType: 'input', input: false, sortable: false, labelWidth: 120 },
  { label: '毛利率%', prop: 'gross_profit_margin', itemType: 'input', input: false, sortable: false, labelWidth: 120 },
  { label: '换汇成本', prop: 'exchange_cost', itemType: 'input', input: false, sortable: false, labelWidth: 120 },
  { label: '采购服务费(6%)', prop: 'purchase_service_fee', itemType: 'input', input: false, sortable: false, labelWidth: 120 }
];
export const expandTableProperties = [
  { label: '年月', prop: '', itemType: 'input', input: false, sortable: false, labelWidth: 80, overflowTooltip: true },
  { label: '客户名称', prop: '', itemType: 'input', input: false, sortable: false, labelWidth: 120, overflowTooltip: true },
  { label: '所属部门', prop: '', itemType: 'input', input: true, sortable: false, labelWidth: 120 },
  { label: '出运发票号', prop: 'tran_no', itemType: 'input', input: false, sortable: false, labelWidth: 120 },
  { label: '报关品名', prop: 'prod_hsnames', itemType: 'input', input: false, sortable: false, labelWidth: 120 },
  {
    label: '实收外汇(＄)',
    prop: 'rece_total',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 120,
    subItem: {
      disabled: row => !['银行手续费', '其他'].includes(row.tran_no),
      type: 'input',
      maxLength: 9,
      input: (val, row) => (row.rece_total = keep2Decimal(val))
    }
  },
  { label: '应收人民币', prop: 'conv_rmb', itemType: 'input', input: false, sortable: false, labelWidth: 120 },
  {
    label: '佣金金额(＄)',
    prop: 'deduct_comm',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 120,
    subItem: {
      disabled: row => !['其他'].includes(row.tran_no),
      type: 'input',
      maxLength: 9,
      input: (val, row) => (row.deduct_comm = keep2Decimal(val))
    }
  },
  { label: '扣除佣金后实收外汇(＄)', prop: 'paid_in_usd', itemType: 'input', input: false, sortable: false, labelWidth: 150 },
  {
    label: '汇率',
    prop: 'purchase_rate',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 130,
    subItem: {
      disabled: row => !['银行手续费', '其他'].includes(row.tran_no),
      type: 'input',
      maxLength: 9,
      input: (val, row) => (row.purchase_rate = keep6Decimal(val))
    }
  },
  { label: '实收人民币', prop: 'paid_in_rmb', itemType: 'input', input: false, sortable: false, labelWidth: 120 },
  {
    label: '工厂货款',
    prop: 'factory_loan',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 120,
    subItem: {
      disabled: row => !['模具费', '面料费', '其他'].includes(row.tran_no),
      type: 'input',
      maxLength: 9,
      input: (val, row) => (row.factory_loan = keep2Decimal(val))
    }
  },
  {
    label: '退税额',
    prop: 'tax_refund',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 120,
    subItem: {
      disabled: row => !['模具费', '面料费', '其他'].includes(row.tran_no),
      type: 'input',
      maxLength: 9,
      input: (val, row) => (row.tax_refund = keep2Decimal(val))
    }
  },
  { label: '毛利1', prop: 'gross_profit_one', itemType: 'input', input: false, sortable: false, labelWidth: 120 },
  {
    label: '包干费(￥)',
    prop: 'rmb_lumpsum_fee',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 120,
    subItem: {
      disabled: row => !['模具费', '银行手续费', '面料费', '其他'].includes(row.tran_no),
      type: 'input',
      maxLength: 9,
      input: (val, row) => (row.rmb_lumpsum_fee = keep2Decimal(val))
    }
  },
  {
    label: '代理费(￥)',
    prop: 'agency_fee',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 120,
    subItem: {
      disabled: row => !['模具费', '银行手续费', '面料费', '其他'].includes(row.tran_no),
      type: 'input',
      maxLength: 9,
      input: (val, row) => (row.agency_fee = keep2Decimal(val))
    }
  },
  { label: '毛利2', prop: 'gross_profit_two', itemType: 'input', input: false, sortable: false, labelWidth: 120 },
  { label: '毛利率%', prop: 'gross_profit_margin', itemType: 'input', input: false, sortable: false, labelWidth: 120 },
  { label: '换汇成本', prop: 'exchange_cost', itemType: 'input', input: false, sortable: false, labelWidth: 120 },
  { label: '采购服务费(6%)', prop: 'purchase_service_fee', itemType: 'input', input: false, sortable: false, labelWidth: 120 }
];
export const otherTableProperties = [
  { label: '客户名称', prop: 'cust_abbr', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '出运发票号', prop: 'tran_no', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '托收合同号', prop: 'contract_no', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '托收金额', prop: 'actual_amount', itemType: 'input', input: false, sortable: false, widthAuto: true }
];
