<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-date-picker
          v-model="dateRange"
          :picker-options="pickerOptions"
          align="right"
          class="vg_ml_16 topDateRangeSearch"
          end-placeholder="结束年月"
          range-separator="至"
          size="small"
          start-placeholder="开始年月"
          type="monthrange"
          unlink-panels
          value-format="timestamp"
        >
        </el-date-picker>
        <el-button class="vg_ml_16" icon="el-icon-search" size="small" type="primary" @click="getTableData()">查询</el-button>
        <el-button class="vd_export" icon="el-icon-refresh-right" size="small" type="info" @click="buttonRefresh()">刷新</el-button>
      </div>
      <search-table
        ref="searchTable"
        v-loading="loadFlag"
        :column-move="false"
        :columns="tableProperties"
        :data="tableData"
        :need-fixed-height="true"
        :need-pagination="false"
        :needExpand="true"
        :table-row-key="'indexId'"
        @expand-change="getRpinInForm"
        @getTableData="getTableData"
        @selection-change="handleSelectionChange"
      >
        <template v-slot:tran_no="scope">
          <el-button size="mini" type="primary" @click="saveData(scope.row)">保存数据</el-button>
        </template>
        <template v-slot:prod_hsnames>
          <el-button size="mini" type="danger">操作数据</el-button>
        </template>
        <template v-slot:expand="scope">
          <div style="padding-left: 98px">
            <DynamicUTable
              :ref="`expandTable${scope.$index}`"
              :autoHeight="true"
              :column-move="false"
              :columns="expandTableProperties"
              :computedData="{
                conv_rmb: conv_rmb,
                paid_in_usd: paid_in_usd,
                paid_in_rmb: paid_in_rmb,
                gross_profit_one: gross_profit_one,
                gross_profit_margin: gross_profit_margin,
                gross_profit_two: gross_profit_two,
                exchange_cost: exchange_cost,
                purchase_service_fee: purchase_service_fee
              }"
              :isExpand="true"
              :need-check-box="false"
              :need-expand="false"
              :need-index="false"
              :need-pagination="false"
              :row-click="(row, column, event) => (currentRow = row)"
              :showHeader="false"
              :table-data="scope.row.rpin_months_list"
            >
            </DynamicUTable>
          </div>
        </template>
      </search-table>
      <h2>托收成本</h2>
      <DynamicUTable
        ref="otherTable"
        :columns="otherTableProperties"
        :need-check-box="false"
        :needPagination="false"
        :needSearch="false"
        :tableData="otherTableData"
      />
    </el-card>
  </div>
</template>

<script>
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { cloneDeep } from 'lodash';
import { setTime } from '@api/public';
import { expandTableProperties, otherTableProperties, tableProperties } from '@/views/StatisticsManagement/RqinMonthStatistics/rqin';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { BigNumber } from 'bignumber.js';
import { rpinmonthAPI } from '@api/modules/rpinmonth';
import { getArrayIds } from '@assets/js/arrayUtils';

export default {
  name: 'RqinList',
  components: {
    SearchTable,
    DynamicUTable
  },
  data() {
    return {
      dateRange: [],
      tableProperties: tableProperties,
      expandTableProperties: expandTableProperties,
      tableData: [],
      totalPage: 0,
      btn: {},
      loadFlag: true,
      minDate: null,
      maxDate: null,
      pickerOptions: {
        disabledDate: time => {
          if (this.minDate !== null && this.maxDate === null) {
            let minMonth = this.minDate.getMonth(),
              lastYear = new Date(this.minDate).setMonth(minMonth - 17),
              nextYear = new Date(this.minDate).setMonth(minMonth + 17);
            // 只能选 minDate 前后一年的范围
            return time.valueOf() < lastYear.valueOf() || time.valueOf() > nextYear.valueOf();
          }
          return false;
        },
        onPick: ({ minDate, maxDate }) => {
          this.minDate = minDate;
          this.maxDate = maxDate;
        },
        shortcuts: [
          {
            text: '本月',
            onClick(picker) {
              picker.$emit('pick', [new Date(), new Date()]);
            }
          },
          {
            text: '今年至今',
            onClick(picker) {
              const end = new Date();
              const start = new Date(new Date().getFullYear(), 0);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setMonth(start.getMonth() - 3);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近六个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setMonth(start.getMonth() - 6);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      },
      currentRow: {},
      otherTableData: [],
      otherTableProperties: otherTableProperties
    };
  },
  computed: {
    // 应收人民币 = 实收外汇 * 汇率
    conv_rmb() {
      let { tran_no, rece_total, purchase_rate } = this.currentRow;
      if (tran_no !== '银行手续费' && tran_no !== '其他') return 0;
      let temp = new BigNumber(rece_total).times(purchase_rate);
      if (!temp.isFinite()) {
        return 0;
      }
      let result = Number(temp.toFixed(2));
      this.currentRow.conv_rmb = result;
      return result;
    },
    // 扣除佣金后外汇 = 实收外汇 - 佣金金额
    paid_in_usd() {
      let { tran_no, rece_total, deduct_comm } = this.currentRow;
      if (tran_no !== '银行手续费' && tran_no !== '其他') return 0;
      let temp = new BigNumber(rece_total).minus(deduct_comm);
      if (!temp.isFinite()) {
        return 0;
      }
      let result = Number(temp.toFixed(2));
      this.currentRow.paid_in_usd = result;
      return result;
    },
    // 实收人民币 = 扣除佣金后外汇 * 汇率
    paid_in_rmb() {
      let { tran_no, paid_in_usd, purchase_rate } = this.currentRow;
      if (tran_no !== '银行手续费' && tran_no !== '其他') return 0;
      let temp = new BigNumber(paid_in_usd).times(purchase_rate);
      if (!temp.isFinite()) {
        return 0;
      }
      let result = Number(temp.toFixed(2));
      this.currentRow.paid_in_rmb = result;
      return result;
    },
    // 毛利1 = 实收人民币 - 工厂货款 + 退税额
    gross_profit_one() {
      let { tran_no, paid_in_rmb, factory_loan, tax_refund } = this.currentRow;
      if (tran_no === '托收金额') return this.currentRow.gross_profit_one;
      let temp = new BigNumber(paid_in_rmb).minus(factory_loan).plus(tax_refund);
      if (!temp.isFinite()) {
        return 0;
      }
      let result = Number(temp.toFixed(2));
      this.currentRow.gross_profit_one = result;
      return result;
    },
    // 毛利率 = 毛利2 / 实收人民币
    gross_profit_margin() {
      let { tran_no, gross_profit_two, paid_in_rmb } = this.currentRow;
      if (tran_no !== '银行手续费' && tran_no !== '其他') return 0;
      let temp = new BigNumber(gross_profit_two).div(paid_in_rmb);
      if (!temp.isFinite()) {
        return 0;
      }
      let result = Number(temp.toFixed(2));
      this.currentRow.gross_profit_margin = result;
      return result;
    },
    // 毛利2 = 毛利1 - 包干费 - 代理费
    gross_profit_two() {
      let { tran_no, gross_profit_one, rmb_lumpsum_fee, agency_fee } = this.currentRow;
      if (tran_no === '托收金额') return this.currentRow.gross_profit_two;
      let temp = new BigNumber(gross_profit_one).minus(rmb_lumpsum_fee).minus(agency_fee);
      if (!temp.isFinite()) {
        return 0;
      }
      let result = Number(temp.toFixed(2));
      this.currentRow.gross_profit_two = result;
      return result;
    },
    // 换汇成本 = ( 工厂货款 - 退税额 + 包干费 + 代理费 ) / 扣除佣金后实收外汇
    exchange_cost() {
      let { tran_no, factory_loan, tax_refund, rmb_lumpsum_fee, agency_fee, paid_in_usd } = this.currentRow;
      if (tran_no !== '银行手续费' && tran_no !== '其他') return 0;
      let temp = new BigNumber(factory_loan).minus(tax_refund).plus(rmb_lumpsum_fee).plus(agency_fee).div(paid_in_usd);
      if (!temp.isFinite()) {
        return 0;
      }
      let result = Number(temp.toFixed(4));
      this.currentRow.exchange_cost = result;
      return result;
    },
    // 采购服务费 = 毛利1 * 0.06
    purchase_service_fee() {
      let { tran_no, gross_profit_one } = this.currentRow;
      if (tran_no === '托收金额') return this.currentRow.purchase_service_fee;
      let temp = new BigNumber(gross_profit_one).times(0.06);
      if (!temp.isFinite()) {
        return 0;
      }
      let result = Number(temp.toFixed(2));
      this.currentRow.purchase_service_fee = result;
      return result;
    }
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.getTableData();
    },
    getTableData() {
      this.loadFlag = true;
      let searchForm = cloneDeep(this.$refs.searchTable.searchForm);
      setTime(searchForm, 'start_time', 'end_time', this.dateRange, false);
      rpinmonthAPI.getRpinMonths(searchForm).then(({ data }) => {
        this.tableData = data;
        this.btn = data.btn;
        this.loadFlag = false;
      });
    },
    // 刷新
    buttonRefresh() {
      this.dateRange = [];
      this.$refs.searchTable.resetFields();
      this.getTableData();
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    saveData(row) {
      rpinmonthAPI.editRpinMonths(row.rpin_months_list).then(({ data }) => {
        this.$message.success('保存成功!');
        this.initData();
      });
    },
    getRpinInForm(row, expanded) {
      let $table = this.$refs.searchTable.$refs.multiTable;
      if (expanded.length > 1) {
        expanded.forEach(item => {
          if (row.indexId !== item.indexId) $table.toggleRowExpansion(item, false);
        });
      }
      let tranNos = getArrayIds(row.rpin_months_list, 'tran_no', true);
      rpinmonthAPI.getRpinInForm({ tran_nos: tranNos }).then(({ data }) => {
        this.otherTableData = data;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-table__expanded-cell {
  padding: 0;
}

::v-deep .elx-table--body-wrapper {
  overflow-x: hidden;
}
</style>
